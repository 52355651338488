
.page ,.modal{
    background-color: #D7F0FD;
    height: 100%;
    position: absolute;
    min-width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.bodyNav {
    background-color: #D7F0FD;
    flex-direction: row;
}

.commonFooterBar {
    position: absolute;
    width: 100%;
    height: 5%;
    bottom: 0%;
    text-align: center;
}

.infoTekst{
    margin-left: 4%;
    margin-right: 0.5%;
    font-size: calc(16px + 2vw);
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
}

.vimeo {
    display: flex;
    justify-content: center;
    margin: 2%;
}