@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap);
html {
  height:100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Make content area fill the entire browser window */
html,
.fullscreen {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Center the content in the browser window */
.container {
  margin: auto;
  text-align: center;
}

.font {
  font-family: 'Roboto Condensed', sans-serif;
}
.header{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 15%;
    font-size: 9vw;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
.container {
    display: flex;
    flex-direction: column;
}

.urlKnappContainer {
    display: flex;
    justify-content: center;
}

.urlKnapp {
    background-color: #3B5998;
    margin: 1%;
    border-radius: 0.6rem;
    padding: 1%;
}

.KnappTekst {
    color: white;
    font-size: calc(16px + 1vw);
    margin: 2%;
}

.page ,.modal{
    background-color: #D7F0FD;
    height: 100%;
    position: absolute;
    min-width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.bodyNav {
    background-color: #D7F0FD;
    flex-direction: row;
}

.commonFooterBar {
    position: absolute;
    width: 100%;
    height: 5%;
    bottom: 0%;
    text-align: center;
}

.infoTekst{
    margin-left: 4%;
    margin-right: 0.5%;
    font-size: calc(16px + 2vw);
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
}

.vimeo {
    display: flex;
    justify-content: center;
    margin: 2%;
}
.epost {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #45A4D3;
  position: absolute;
  width: 100%;
  height: 5%;
  bottom: 0%;
  border-radius: 0.5rem;
}

.tekst{
  font-size: calc(16px + 2vw);
  color: black;
  font-family: 'Roboto Condensed', sans-serif;
}



.hs-body {
  height: 80%;
  align-self: stretch;
  margin-bottom: 5%;
}

.buttons {
  min-height: 100%;
}

.layout {
  display: flex;
  height: 25%;
}

.boks {
  margin: 1%;
  width: 50%;
  justify-content: center;
  border-radius: 1rem;
}

.hvit{
  color: white;
}

.svart{
  color: black;
}

.knappTekst {
  /* font-size: 7vw; */
  font-size: calc(16px + 3vw);
  font-family: 'Roboto Condensed', sans-serif;
  white-space: pre-line;
}

.rad1 {
  background-color: #2a6794;
}


.rad2 {
  background-color: #3685BF
}


.rad3 {
  background-color: #45A4D3
}


.rad4 {
  background-color: #72D2E7
}

.modalBoks{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.modalKnapp{
  width: 100%;
  border-radius: 1.5rem;
  background-color: #72D2E7;
  border-width: 0;
}
.Modal {
    position: absolute;
    inset: 2%;
    background-color: white;
  }

form {
    height: 100%;
}
  
textarea {
    height: 100%;
    width: 100%;
    border: black;
    font-size: calc(16px + 1vw);
    resize: none;
}

  .notatFooterBar {
    position: absolute;
    height: 10%;
    width: 100%;
    justify-content: center;
    bottom: 0%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
} 

.notatButton {
    min-height: 90%;
    border-radius: 1.5rem;
    background-color: #A9A9A9;
    width: 95%;
}
.navigFooterBar {
    position: absolute;
    bottom: 0%;
    display: flex;
    background-color: #D7F0FD;
    width: 100%;
    height: 10%;
    justify-content: space-evenly ;
}

.largeFooterButton, .smallFooterButton, .notatFooterButton{
    border-radius: 1rem;
    background-color: #00BFFF;
    height: 95%;
}

.largeFooterButton{
    width: 80%;
}

.smallFooterButton{
    width: 40%;
}

.notatFooterButton{
    width: 15%;
}
html,
.fullscreen {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
    font-size: calc(16px + 4.5vw);
    font-weight: normal;
}

h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: calc(16px + 2vw);
    text-align: center;
    margin-top: 7%;
    margin-left: 1%;
    margin-right: 1%;
}

img {
    display: block;
    max-width: 10vmin;
    margin: auto;
}


.steg-side {
    flex: 1 1;
    background-color: #D7F0FD;
}

.body {
    margin-bottom: 10%;
    height: 75%;
    align-self: stretch;
}

.bakgrunn {
    flex: 1 1;
    background-color: #D7F0FD
}

.linkFarge, .BodyNavig {
    background-color: #BCE6FD
}

.BodyNavig {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.linkTekst, .infoLink {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 2vw);
    text-align: left;
    padding-left: 2%;
    margin-left: 4%;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin: auto;
}

.infoLink {
    font-style: italic;
    padding: 2%;
}

.emptyLine{
    height: 1%;
}

.youtube {
    display: flex;
    justify-content: center;
}

li {
    list-style-type: none;
}

.scroll {
    margin-left: 5%;
    margin-right: 5%;
}

.elementer {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 90%;
}

.valgKnapp, .tilStartKnapp {
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 3%;
    padding: 0.6%;
    border-radius: 10rem;
    border-width: 0.2rem;
    border-color: black;
}

.tilStartKnapp {
    width: 30%;
    margin-top: 6%;
}

.tilStartKnappContainer {
    display: flex;
    justify-content: flex-end;
}

.svarBoks {
    margin-top: 2rem;
    padding: 0.7rem;
    border: 0.4rem solid #3685BF;
    background-color: #BCE6FD;
}

.forklaring, .valgKnappTekst, .svarTekst {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 0.5vw);
    text-align: left;
    margin: 1%;
    margin-bottom: 1%;
}

.valgKnappTekst {
    text-align: center;
    padding: inherit;
    font-weight: 700;
    margin-left: 5%;
    margin-right: 5%;
    color: black;
}

.svarTekst{
    margin-bottom: 3%;
    font-size: calc(16px + 1vw);
}

.forklaring{
    margin-bottom: 1.5%;
    margin-top: 1.5%;
    align-self: center;
    font-size: calc(16px + 1vw);
}

.forklaringRad {
    display: flex;
    flex-direction: row;
}

.bold {
    font-weight: 700;
}

.sirkel {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.3rem solid #3685BF;
    border-radius: 5rem;
    margin: 0.4rem;
    margin-bottom: 0.5rem;
}

.gul {
    background-color: #FFFACD;
}

.grønn {
    background-color: #98FB98;
}

.rød {
    background-color: #FF7F50;
}


.modalLink {
    font-size: calc(16px + 2vw);
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
}

.linkText {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin: 0;
}

.darkRow {
    background-color: #BCE6FD;
}

.veiledning {
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 0.5vw);
}

.svarHeader {
    text-align: center;
    font-size: calc(16px + 2vw);
    margin: 2%;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.svarBody {
    height: 60%;
}

.svar {
    font-size: calc(16px + 1.5vw);
    margin-left: 1%;
    font-family: 'Roboto Condensed', sans-serif;
}
