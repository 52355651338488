.modalLink {
    font-size: calc(16px + 2vw);
    color: black;
    font-family: 'Roboto Condensed', sans-serif;
}

.linkText {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin: 0;
}

.darkRow {
    background-color: #BCE6FD;
}

.veiledning {
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 0.5vw);
}

.svarHeader {
    text-align: center;
    font-size: calc(16px + 2vw);
    margin: 2%;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
}

.svarBody {
    height: 60%;
}

.svar {
    font-size: calc(16px + 1.5vw);
    margin-left: 1%;
    font-family: 'Roboto Condensed', sans-serif;
}