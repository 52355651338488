li {
    list-style-type: none;
}

.scroll {
    margin-left: 5%;
    margin-right: 5%;
}

.elementer {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 90%;
}

.valgKnapp, .tilStartKnapp {
    height: fit-content;
    margin-bottom: 3%;
    padding: 0.6%;
    border-radius: 10rem;
    border-width: 0.2rem;
    border-color: black;
}

.tilStartKnapp {
    width: 30%;
    margin-top: 6%;
}

.tilStartKnappContainer {
    display: flex;
    justify-content: flex-end;
}

.svarBoks {
    margin-top: 2rem;
    padding: 0.7rem;
    border: 0.4rem solid #3685BF;
    background-color: #BCE6FD;
}

.forklaring, .valgKnappTekst, .svarTekst {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 0.5vw);
    text-align: left;
    margin: 1%;
    margin-bottom: 1%;
}

.valgKnappTekst {
    text-align: center;
    padding: inherit;
    font-weight: 700;
    margin-left: 5%;
    margin-right: 5%;
    color: black;
}

.svarTekst{
    margin-bottom: 3%;
    font-size: calc(16px + 1vw);
}

.forklaring{
    margin-bottom: 1.5%;
    margin-top: 1.5%;
    align-self: center;
    font-size: calc(16px + 1vw);
}

.forklaringRad {
    display: flex;
    flex-direction: row;
}

.bold {
    font-weight: 700;
}

.sirkel {
    width: 2.5rem;
    height: 2.5rem;
    border: 0.3rem solid #3685BF;
    border-radius: 5rem;
    margin: 0.4rem;
    margin-bottom: 0.5rem;
}

.gul {
    background-color: #FFFACD;
}

.grønn {
    background-color: #98FB98;
}

.rød {
    background-color: #FF7F50;
}

