.Modal {
    position: absolute;
    inset: 2%;
    background-color: white;
  }

form {
    height: 100%;
}
  
textarea {
    height: 100%;
    width: 100%;
    border: black;
    font-size: calc(16px + 1vw);
    resize: none;
}

  .notatFooterBar {
    position: absolute;
    height: 10%;
    width: 100%;
    justify-content: center;
    bottom: 0%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
} 

.notatButton {
    min-height: 90%;
    border-radius: 1.5rem;
    background-color: #A9A9A9;
    width: 95%;
}