.navigFooterBar {
    position: absolute;
    bottom: 0%;
    display: flex;
    background-color: #D7F0FD;
    width: 100%;
    height: 10%;
    justify-content: space-evenly ;
}

.largeFooterButton, .smallFooterButton, .notatFooterButton{
    border-radius: 1rem;
    background-color: #00BFFF;
    height: 95%;
}

.largeFooterButton{
    width: 80%;
}

.smallFooterButton{
    width: 40%;
}

.notatFooterButton{
    width: 15%;
}