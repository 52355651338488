.header{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 15%;
    font-size: 9vw;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}