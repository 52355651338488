html,
.fullscreen {
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
    font-size: calc(16px + 4.5vw);
    font-weight: normal;
}

h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: calc(16px + 2vw);
    text-align: center;
    margin-top: 7%;
    margin-left: 1%;
    margin-right: 1%;
}

img {
    display: block;
    max-width: 10vmin;
    margin: auto;
}


.steg-side {
    flex: 1;
    background-color: #D7F0FD;
}

.body {
    margin-bottom: 10%;
    height: 75%;
    align-self: stretch;
}

.bakgrunn {
    flex: 1;
    background-color: #D7F0FD
}

.linkFarge, .BodyNavig {
    background-color: #BCE6FD
}

.BodyNavig {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.linkTekst, .infoLink {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: calc(16px + 2vw);
    text-align: left;
    padding-left: 2%;
    margin-left: 4%;
    text-decoration-line: underline;
    margin: auto;
}

.infoLink {
    font-style: italic;
    padding: 2%;
}

.emptyLine{
    height: 1%;
}

.youtube {
    display: flex;
    justify-content: center;
}
