
.hs-body {
  height: 80%;
  align-self: stretch;
  margin-bottom: 5%;
}

.buttons {
  min-height: 100%;
}

.layout {
  display: flex;
  height: 25%;
}

.boks {
  margin: 1%;
  width: 50%;
  justify-content: center;
  border-radius: 1rem;
}

.hvit{
  color: white;
}

.svart{
  color: black;
}

.knappTekst {
  /* font-size: 7vw; */
  font-size: calc(16px + 3vw);
  font-family: 'Roboto Condensed', sans-serif;
  white-space: pre-line;
}

.rad1 {
  background-color: #2a6794;
}


.rad2 {
  background-color: #3685BF
}


.rad3 {
  background-color: #45A4D3
}


.rad4 {
  background-color: #72D2E7
}

.modalBoks{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.modalKnapp{
  width: 100%;
  border-radius: 1.5rem;
  background-color: #72D2E7;
  border-width: 0;
}