.epost {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #45A4D3;
  position: absolute;
  width: 100%;
  height: 5%;
  bottom: 0%;
  border-radius: 0.5rem;
}

.tekst{
  font-size: calc(16px + 2vw);
  color: black;
  font-family: 'Roboto Condensed', sans-serif;
}

