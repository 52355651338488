.container {
    display: flex;
    flex-direction: column;
}

.urlKnappContainer {
    display: flex;
    justify-content: center;
}

.urlKnapp {
    background-color: #3B5998;
    margin: 1%;
    border-radius: 0.6rem;
    padding: 1%;
}

.KnappTekst {
    color: white;
    font-size: calc(16px + 1vw);
    margin: 2%;
}